import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import levenshtein from 'fast-levenshtein';
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BarPlot from './BarPlot';

var dawaAutocomplete2 = require('dawa-autocomplete2');


const AddressInput = ({ onSelectOwner, style, ownerNames, onSelectPriceArea  }) => {
    const [address, setAddress] = useState('');
    const [error, setError] = useState(''); // State to store the error message
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            dawaAutocomplete2.dawaAutocomplete(inputRef.current, {
                select: (selected) => {
                    setAddress(selected.tekst);

                    const zip = parseInt(selected.data.postnr, 10);
                    const newArea = zip >= 5000 ? 'DK1' : 'DK2';
                    if (onSelectPriceArea) {
                        onSelectPriceArea(newArea); // Make sure the callback is called here
                      }
                }
            });
        }
    }, [inputRef, onSelectPriceArea]);

    const findClosestMatchingOwner = (apiOwnerName) => {
        let closestMatch = '';
        let smallestDistance = Infinity;
        const apiOwnerPrefix = apiOwnerName.substring(0, 4).toLowerCase();


        ownerNames.forEach(ownerName => {
            const ownerPrefix = ownerName.substring(0, 4).toLowerCase();
            const distance = levenshtein.get(apiOwnerPrefix, ownerPrefix);
            //console.log("LEVENSHTEIN DISTANCE:", distance,"From:",apiOwnerPrefix,"To:", ownerPrefix)
            if (distance < smallestDistance) {
                smallestDistance = distance;
                closestMatch = ownerName;
            }
        });
        //console.log("CLOSEST MATCH FROM LEVENSHTEIN",closestMatch)
        return closestMatch;
    };

    const fetchOwner = () => {
        const url = `https://api.elnet.greenpowerdenmark.dk/api/supplierlookup/${encodeURIComponent(address)}`;
        //console.log(url)
        axios.get(url)
            .then(response => {

                const result = response.data.name;
                console.log("RESULT", result)
                const ownerName = findClosestMatchingOwner(result)
                //console.log("FETCHED OWNER NAME:",ownerName,ownerNames)
                onSelectOwner(ownerName);
            })
            .catch(error => {
                console.error('Error fetching owner:', error);
                setError('Det var ikke muligt at finde ElSelskab ud fra denne Adresse. Prøv igen'); // Set error message

            });
    };

    return (
        <div className="autocomplete-container">
            <div className='searchWrapper'>
                <input
                    ref={inputRef}
                    type="text"
                    id="dawa-autocomplete-input"
                    className='input searchInput'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Indtast adresse"

                />
                <button
                    className='searchButton'
                    onClick={fetchOwner}
                >
                    <i className="fa-solid fa-magnifying-glass"></i>
                </button>
            </div>
        </div>
    );
};


export default AddressInput;
